// extracted by mini-css-extract-plugin
export var lbContainerOuter = "w_sS";
export var lbContainerInner = "w_sT";
export var movingForwards = "w_sV";
export var movingForwardsOther = "w_sW";
export var movingBackwards = "w_sX";
export var movingBackwardsOther = "w_sY";
export var lbImage = "w_sZ";
export var lbOtherImage = "w_s0";
export var prevButton = "w_s1";
export var nextButton = "w_s2";
export var closing = "w_s3";
export var appear = "w_s4";