// extracted by mini-css-extract-plugin
export var alignDiscLeft = "s_qV d_fm d_bD d_ds";
export var alignLeft = "s_qc d_fm d_bD d_ds";
export var alignDiscCenter = "s_qW d_fn d_bB d_dt";
export var alignCenter = "s_bM d_fn d_bB d_dt";
export var alignDiscRight = "s_qX d_fp d_bF d_dv";
export var alignRight = "s_qd d_fp d_bF d_dv";
export var footerContainer = "s_qY d_dT d_bT";
export var footerContainerFull = "s_qZ d_dR d_bT";
export var footerHeader = "s_kb d_kb";
export var footerTextWrapper = "s_q0 d_t";
export var footerDisclaimerWrapper = "s_kj d_kj d_ch";
export var badgeWrapper = "s_q1 d_t d_bx d_bM d_bC d_bG";
export var footerDisclaimerRight = "s_q2 undefined";
export var footerDisclaimerLeft = "s_q3 undefined";
export var verticalTop = "s_q4 d_bx d_bG d_bL d_bJ";
export var firstWide = "s_q5";
export var disclaimer = "s_q6 d_bx d_bG";
export var socialDisclaimer = "s_q7";
export var left = "s_q8";
export var wide = "s_q9 d_cw";
export var right = "s_rb d_bH";
export var line = "s_fh d_fj d_cs";
export var badgeDisclaimer = "s_rc d_bz d_bM d_bG";
export var badgeContainer = "s_rd d_bx d_bF d_bM";
export var badge = "s_rf";
export var padding = "s_rg d_c5";
export var end = "s_rh d_bF";
export var linkWrapper = "s_rj d_dy";
export var link = "s_mW d_dy";
export var colWrapper = "s_rk d_cv";
export var consent = "s_f d_f d_bz d_bM";
export var media = "s_rl d_bv d_dw";
export var itemRight = "s_rm";
export var itemLeft = "s_rn";
export var itemCenter = "s_rp";
export var exceptionWeight = "s_rq t_rT";