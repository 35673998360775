// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "q_qq d_gr d_cq";
export var heroHeaderCenter = "q_gs d_gs d_cq d_dt";
export var heroHeaderRight = "q_gt d_gt d_cq d_dv";
export var heroParagraphLeft = "q_qr d_gn d_ct";
export var heroParagraphCenter = "q_gp d_gp d_ct d_dt";
export var heroParagraphRight = "q_gq d_gq d_ct d_dv";
export var heroBtnWrapperLeft = "q_qs d_d0 d_dZ d_t d_bx d_bD";
export var heroBtnWrapperCenter = "q_qt d_d1 d_dZ d_t d_bx d_bB";
export var heroBtnWrapperRight = "q_qv d_d2 d_dZ d_t d_bx d_bF";
export var overlayBtnWrapper = "q_qw d_gm d_Y d_5 d_6 d_7 d_bl d_b9";
export var design4 = "q_qx d_gl d_Y d_5 d_6 d_bl";
export var heroExceptionSmall = "q_qy t_qy";
export var heroExceptionNormal = "q_qz t_qz";
export var heroExceptionLarge = "q_qB t_qB";
export var Title1Small = "q_qC t_qC t_rr t_rs";
export var Title1Normal = "q_qD t_qD t_rr t_rt";
export var Title1Large = "q_qF t_qF t_rr t_rv";
export var BodySmall = "q_qG t_qG t_rr t_rL";
export var BodyNormal = "q_qH t_qH t_rr t_rM";
export var BodyLarge = "q_qJ t_qJ t_rr t_rN";