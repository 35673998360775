// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "r_qK d_bB";
export var storyRowWrapper = "r_ht d_ht d_bH";
export var storyLeftWrapper = "r_qL d_bx d_bM";
export var storyWrapperFull = "r_qM d_cB";
export var storyWrapperFullLeft = "r_nL d_cB d_bx d_bM d_bB";
export var contentWrapper = "r_mS d_hv";
export var storyLeftWrapperCenter = "r_qN d_hw d_bx d_bB d_bM d_bG";
export var storyRightWrapperCenter = "r_qP d_hB";
export var storyHeader = "r_qQ d_hz d_t d_cq";
export var storyHeaderCenter = "r_hy d_hy d_t d_cq d_dt d_bw";
export var storyParagraphCenter = "r_hx d_hx d_bw d_dt";
export var storyBtnWrapper = "r_qR d_d0 d_dZ d_t d_bx d_bD";
export var storyBtnWrapperCenter = "r_hF d_hF d_dZ d_t d_bx d_bB";
export var imageWrapper = "r_qS d_fd d_X";
export var imageWrapperFull = "r_qT d_t d_F d_bc d_X";